import { deepEqual } from "fast-equals";
import { ModelState, IJsonResponse, IUserData } from "@cloudfun/core";
import { Module } from "vuex";
import XEUtils from "xe-utils";
import type { Employee, Order } from "./data";

interface ReservationState {
  EmployeeId: string;
  Employee: string;
  CustomerId: string;
  Customer: string;
}

interface State {
  data: ReservationState;
}

const module: Module<State, ModelState> = {
  namespaced: true,
  state: {
    data: { EmployeeId: "", Employee: "", CustomerId: "", Customer: "" },
  },
  getters: {
    data: (state) => state.data,
  },
  mutations: {
    set(state, payload) {
      state.data = payload;
    },
  },
  actions: {
    set({ commit }, payload: ReservationState) {
      commit("set", {
        EmployeeId: payload.EmployeeId,
        Employee: payload.Employee,
        CustomerId: payload.CustomerId,
        Customer: payload.Customer,
      });
    },
  },
};

export default module;
