import { ISitemapNode } from "@cloudfun/core";

const sitemap: ISitemapNode = {
  icon: "HomeIcon",
  to: "/",
  title: "menu.home",
  subNodes: [
    {
      icon: "/images/icon-user.svg",
      title: "員工客戶管理",
      subNodes: [
        {
          icon: "iconsminds-male-female",
          title: "menu.employee.main",
          subNodes: [
            {
              icon: "iconsminds-male-female",
              title: "menu.employee.info",
              to: "/employee/info",
            },
            {
              icon: "iconsminds-calendar-4",
              title: "menu.employee.calendar.daily",
              to: "/employee/calendar/daily",
            },
            {
              icon: "iconsminds-calendar-4",
              title: "menu.employee.calendar.weekly",
              to: "/employee/calendar/weekly",
            },
            // {
            //   icon: "iconsminds-calendar-4",
            //   title: "menu.employee.calendar.monthly",
            //   to: "/employee/calendar/monthly",
            // },
            {
              icon: "iconsminds-summer",
              title: "menu.employee.calendar.leave",
              to: "/employee/calendar/leave",
            },
          ],
        },
        {
          icon: "iconsminds-business-man-woman",
          title: "menu.customer",
          subNodes: [
            {
              icon: "iconsminds-business-man-woman",
              title: "menu.customer",
              to: "/customer",
            },
            {
              icon: "iconsminds-crown-2",
              title: "menu.basis.grade.customer",
              to: "/basis/grade/customer",
            },
          ],
        },
        {
          icon: "iconsminds-factory",
          title: "menu.basis.supplier",
          to: "/basis/supplier",
        },
        {
          icon: "iconsminds-male-female",
          title: "menu.basis.skill",
          to: "/basis/skill",
        },
        {
          icon: "iconsminds-unlock-2",
          title: "menu.employee.platform",
          to: "/employee/platform",
        },
      ],
    },
    {
      icon: "/images/icon-note.svg",
      title: "功能管理",
      subNodes: [
        {
          icon: "iconsminds-box-with-folders", //"iconsminds-shopping-cart",
          title: "menu.order",
          to: "/order-list",
        },
        {
          icon: "iconsminds-box-with-folders", //"iconsminds-shopping-cart",
          title: "menu.order-record",
          to: "/order-record",
        },
        {
          icon: "iconsminds-folders",
          title: "menu.basis.merchandise.main",
          subNodes: [
            {
              icon: "simple-icon-organization",
              title: "menu.basis.merchandise.category",
              to: "/basis/merchandise/category",
            },
            {
              icon: "simple-icon-organization",
              title: "menu.basis.merchandise.product-category",
              to: "/basis/merchandise/productCategory",
            },
            // {
            //   icon: "iconsminds-folders",
            //   title: "menu.basis.merchandise.inventory",
            //   to: "/basis/merchandise/inventory",
            // },
            {
              icon: "iconsminds-folders",
              title: "menu.basis.merchandise.service-item",
              to: "/basis/merchandise/service-item",
            },
            {
              icon: "simple-icon-organization",
              title: "menu.basis.merchandise.material-category",
              to: "/basis/merchandise/material-category",
            },
            {
              icon: "iconsminds-folders",
              title: "menu.basis.merchandise.material",
              to: "/basis/merchandise/material",
            },
          ],
        },
      ],
    },
    {
      icon: "/images/icon-stock.svg",
      title: "店販物料管理",
      subNodes: [
        {
          icon: "simple-icon-user-follow",
          title: "menu.basis.invoicing.main",
          // to: "/basis/invoicing/material-request",
          to: "/basis/invoicing",
          subNodes: [
            {
              icon: "iconsminds-folder-edit",
              title: "menu.basis.invoicing.material-request",
              to: "/basis/invoicing/material-request",
            },
            {
              icon: "iconsminds-folder-edit",
              title: "menu.basis.invoicing.material-record",
              to: "/basis/invoicing/material-record",
            },
            {
              icon: "iconsminds-folder-edit",
              title: "menu.basis.invoicing.procurement",
              to: "/basis/invoicing/procurement",
            },
            {
              icon: "iconsminds-folder-edit",
              title: "menu.basis.invoicing.inspection",
              to: "/basis/invoicing/picking",
            },
            {
              icon: "iconsminds-folder-edit",
              title: "menu.basis.invoicing.purchase",
              to: "/basis/invoicing/purchase",
            },
            {
              icon: "iconsminds-folder-edit",
              title: "menu.basis.invoicing.purchase-return",
              to: "/basis/invoicing/purchase-return",
            },
            {
              icon: "iconsminds-folder-edit",
              title: "menu.basis.invoicing.inventory",
              to: "/basis/invoicing/inventory",
            },
            {
              icon: "iconsminds-folder-edit",
              title: "menu.basis.invoicing.inventory-record",
              to: "/basis/invoicing/inventory-record",
            },
          ],
        },
        {
          icon: "iconsminds-bar-chart-4",
          title: "menu.report.main",
          subNodes: [
            {
              icon: "iconsminds-coins",
              title: "menu.report.revenue-flow",
              to: "/report/revenue-flow",
            },
            {
              icon: "iconsminds-coins",
              title: "menu.report.revenue",
              to: "/report/revenue",
            },
            {
              icon: "iconsminds-coins",
              title: "menu.report.performance",
              to: "/report/performance",
            },
            {
              icon: "iconsminds-mens",
              title: "menu.report.age-group",
              to: "/report/age-group",
            },
            {
              icon: "iconsminds-handshake",
              title: "menu.report.salary",
              to: "/report/salary",
            },
            {
              icon: "iconsminds-handshake",
              title: "menu.report.employee-sales",
              to: "/report/employee-sales",
            },
            {
              icon: "iconsminds-handshake",
              title: "menu.report.merchandise-sales",
              to: "/report/merchandise-sales",
            },
          ],
        },
      ],
    },
    {
      icon: "/images/icon-system.svg",
      title: "設定",
      subNodes: [
        {
          icon: "simple-icon-info",
          title: "menu.basis.store.main",
          subNodes: [
            {
              icon: "iconsminds-shop",
              title: "menu.basis.store.info",
              to: "/basis/store/info",
            },
            {
              icon: "iconsminds-chair",
              title: "menu.basis.store.position",
              to: "/basis/store/position",
            },
            {
              icon: "iconsminds-chair",
              title: "menu.basis.store.partition",
              to: "/basis/store/partition",
            },
            {
              icon: "iconsminds-equalizer",
              title: "menu.basis.store.equipment",
              to: "/basis/store/equipment",
            },
            {
              icon: "iconsminds-equalizer",
              title: "menu.basis.store.printer",
              to: "/basis/store/printer",
            },
          ],
        },
      ],
    },
  ],
};

export default sitemap;
