/* eslint-disable */

import { deepEqual } from "fast-equals";
import { ModelState, IJsonResponse, IUserData } from "@cloudfun/core";
import { Module } from "vuex";
import type { CustomerConsumption } from "./data";
import { generateHeaders } from "@/utils";

const MAX_ALIVE_TIME = 15000;
const apiPath = "/Order";

interface State {
  loadingTime?: Date;
  params: any;
  totalCount: number;
  rows: any[];
}

const module: Module<State, ModelState> = {
  namespaced: true,
  state: {
    params: {},
    totalCount: 0,
    rows: [],
  },
  getters: {
    isExpired: (state) =>
      !state.loadingTime ||
      new Date().valueOf() - state.loadingTime.valueOf() < MAX_ALIVE_TIME,
    page: (state) => state.params.page,
    pageSize: (state) => state.params.pageSize,
    condition: (state) => state.params.condition,
    sortings: (state) => state.params.sortings,
    totalCount: (state) => state.totalCount,
    rows: (state) => state.rows,
  },
  mutations: {
    setPage(state, page) {
      if (state.params.page == page) return;
      state.params.page = page;
      state.loadingTime = undefined;
    },
    setPageSize(state, pageSize) {
      if (state.params.pageSize == pageSize) return;
      state.params.pageSize = pageSize;
      state.loadingTime = undefined;
    },
    setCondition(state, condition) {
      if (deepEqual(state.params.condition, condition)) return;
      state.params.condition = condition;
      state.loadingTime = undefined;
    },
    setSortings(state, sortings) {
      if (deepEqual(state.params.sortings, sortings)) return;
      state.params.sortings = sortings;
      state.loadingTime = undefined;
    },
    setParams(state, params) {
      if (deepEqual(state.params, params)) return;
      state.params = params;
      state.loadingTime = undefined;
    },
    setRows(state, rows) {
      state.loadingTime = new Date();
      state.rows = rows;
    },
    setTotalCount(state, totalCount) {
      state.loadingTime = new Date();
      state.totalCount = totalCount;
    },
    setLoadingTime: (state, time) => (state.loadingTime = time),
  },
  actions: {
    find: ({ rootState, state, getters }, key) =>{
      //console.log(key)
      return new Promise<any>((resolve, reject) => {
        rootState.clients.authorized.get(`${apiPath}/${key}`,{headers: generateHeaders(rootState)}).then(
          (success: IJsonResponse<any>) => resolve(success.payload),
          (failure: IJsonResponse<any>) => reject(failure.message)
        );
      })},
    insert: ({ rootState, commit }, data, ) =>
      new Promise<any>((resolve, reject) => {
        rootState.clients.authorized.post(apiPath, data, {headers: generateHeaders(rootState)}).then(
          (success: IJsonResponse<any>) => {
            commit("setLoadingTime", null); // reset loading time
            resolve(success.payload!);
          },
          (failure: IJsonResponse<any>) => reject(failure.message)
        );
      }),
    // update: ({ rootState, commit }, data) =>
    //   new Promise<any>((resolve, reject) => {
    //     rootState.clients.authorized.put(apiPath, data).then(
    //       (success: IJsonResponse<any>) => {
    //         commit("setLoadingTime", null);
    //         resolve(success.payload!);
    //       },
    //       (failure: IJsonResponse<any>) => reject(failure.message)
    //     );
    //   }),
    delete: ({ rootState, commit }, key) =>
      new Promise((resolve, reject) => {
        rootState.clients.authorized.delete(apiPath, { params: { key }, headers: generateHeaders(rootState) }).then(
          (success: IJsonResponse<any>) => {
            commit("setLoadingTime", null);
            resolve(success.payload);
          },
          (failure: IJsonResponse<any>) => reject(failure.message)
        );
      }),
    save: ({ rootState, commit }, changes) =>
      new Promise((resolve, reject) => {
        rootState.clients.authorized.post(`${apiPath}/Save`, changes,{headers: generateHeaders(rootState)}).then(
          (success: IJsonResponse<any>) => {
            commit("setLoadingTime", null);
            resolve(success.payload);
          },
          (failure: IJsonResponse<any>) => reject(failure.message)
        );
      }),
    query: ({ rootState }, params) =>
      new Promise((resolve, reject) => {
        rootState.clients.authorized.get(apiPath, { params, headers: generateHeaders(rootState) }).then(
          (success: IJsonResponse<any>) => resolve(success.payload),
          (failure: IJsonResponse<any>) => reject(failure.message)
        );
      }),
    load: ({ rootState, state, commit }, params = null) =>
      new Promise((resolve, reject) => {
        if (params) commit("setParams", params);
        params = { ...state.params };
        console.log("send params: ", JSON.stringify(params));
        rootState.clients.authorized.get(apiPath, { params, headers: generateHeaders(rootState) }).then(
          (success: IJsonResponse<any>) => {
            commit("setLoadingTime", new Date());
            commit("setTotalCount", success.payload.totalCount);
            commit("setRows", success.payload.data);
            console.log("load", success.payload);
            resolve(success.payload);
          },
          (failure: IJsonResponse<any>) => reject(failure.message)
        );
      }),
    customerConsumptions: ({ rootState }, params) =>
      new Promise<CustomerConsumption | undefined>((resolve, reject) => {
        rootState.clients.authorized
          .get(`${apiPath}/CustomerConsumptions`, { params, headers: generateHeaders(rootState) })
          .then(
            (success: IJsonResponse<CustomerConsumption>) =>
              resolve(success.payload),
            (failure: IJsonResponse<any>) => reject(failure.message)
          );
      }),
    statistics: ({ rootState }, params) =>
      new Promise<string | undefined>((resolve, reject) => {
        rootState.clients.authorized
          .get(`${apiPath}/Statistics`, { params, headers: generateHeaders(rootState) })
          .then(
            (success: IJsonResponse<string>) => resolve(success.payload),
            (failure: IJsonResponse<any>) => reject(failure.message)
          );
      }),
    printStatistics: ({ rootState }, params) =>
      new Promise<string | undefined>((resolve, reject) => {
        rootState.clients.authorized
          .get(`${apiPath}/Statistics/Print`, { params, headers: generateHeaders(rootState) })
          .then(
            (success: IJsonResponse<string>) => resolve(success.payload),
            (failure: IJsonResponse<any>) => reject(failure.message)
          );
      }),
    printBill: ({ rootState }, params) =>
      new Promise<string | undefined | undefined>((resolve, reject) => {
        rootState.clients.authorized
          .get(`${apiPath}/PrintBill`, { params, headers: generateHeaders(rootState)})
          .then(
            (success: IJsonResponse<string | undefined>) =>
              resolve(success.payload),
            (failure: IJsonResponse<any>) => reject(failure.message)
          );
      }),
    changeEmployee: (
      { rootState, commit },
      data: { OrderId: string; EmployeeId: string }
    ) =>
      new Promise<any>((resolve, reject) => {
        rootState.clients.authorized
          .post(`${apiPath}/ChangeEmployee`, { entity: data },{headers: generateHeaders(rootState)})
          .then(
            (success: IJsonResponse<any>) => {
              commit("setLoadingTime", null);
              resolve(success.payload!);
            },
            (failure: IJsonResponse<any>) => reject(failure.message)
          );
      }),
    changeCustomer: (
        { rootState, commit }, data
      ) =>
        new Promise<any>((resolve, reject) => {
          rootState.clients.authorized
            .post(`${apiPath}/ChangeCustomer`, data, {headers: generateHeaders(rootState)})
            .then(
              (success: IJsonResponse<any>) => {
                commit("setLoadingTime", null);
                resolve(success.payload!);
              },
              (failure: IJsonResponse<any>) => reject(failure.message)
            );
        }),
    changeOrder: ({ rootState, commit }, data) =>
      new Promise<any>((resolve, reject) => {
        rootState.clients.authorized
          .post(`${apiPath}/ChangeOrder`, { entity: data },{headers: generateHeaders(rootState)})
          .then(
            (success: IJsonResponse<any>) => {
              commit("setLoadingTime", null);
              resolve(success.payload!);
            },
            (failure: IJsonResponse<any>) => reject(failure.message)
          );
      }),
    accept: ({ rootState, state, getters }, key) =>
      new Promise<any>((resolve, reject) => {
        rootState.clients.authorized
          .get(`${apiPath}/Accept?orderId=${key}`)
          .then(
            (success: IJsonResponse<any>) => resolve(success.payload!),
            (failure: IJsonResponse<any>) => reject(failure.message)
          );
      }),
    statusChange: ({ rootState, state, getters }, params) =>
      new Promise<any>((resolve, reject) => {
        rootState.clients.authorized
          .get(
            `${apiPath}/Status/Change?orderId=${params.id}&status=${params.status}`, { params, headers: generateHeaders(rootState)}
          )
          .then(
            (success: IJsonResponse<any>) => resolve(success.payload!),
            (failure: IJsonResponse<any>) => reject(failure.message)
          );
      }),
  },
};

export default module;
