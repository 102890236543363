

import CloudFun from "@cloudfun/core";
import { defineComponent, ref } from "@cloudfun/core";
import Swal from "sweetalert2";
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  Mousewheel,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import FileUploader from "./CfFileUploader.vue";

import "swiper/swiper-bundle.min.css";

SwiperCore.use([Navigation, Pagination, Autoplay, Mousewheel]);

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    FileUploader,
  },
  props: {
    mousewheel: { type: Boolean, default: true },
    navigation: { type: Boolean, dafault: true },
    pagination: { type: Boolean, default: true },
    pageSize: { type: Number, default: 3 },
    itemSpace: { type: Number, default: 15 },
    itemWidth: { type: Number, default: 800 },
    itemHeight: { type: Number, default: 600 },
    uploaderWidth: { type: Number, default: 300 } ,
    uploaderHeight: { type: Number, default: 200 },
    maxImages: { type:Number, default: 5 },
    galleryId: String,
    lazy: { type: Boolean, dafault: true },
    width: String,
    title: { type: String, default: "相片" },
    filePostAction: String,
    modelValue: Array,
    formOptions: {
      type: Object,
      default: () => {
        return {
          titleWidth: 40,
          titleAlign: "right",
          items: [
            {
              field: "Name",
              title: "名稱",
              span: 24,
              itemRender: {
                name: "$input",
                props: { placeholder: "請輸入文字", clearable: true },
              },
            },
            {
              span: 24,
              align: "right",
              itemRender: {
                name: "$buttons",
                children: [
                  {
                    props: {
                      type: "submit",
                      content: "確定",
                      status: "primary",
                    },
                  },
                  { props: { type: "reset", content: "清除" } },
                ],
              },
            },
          ],
        };
      },
    },
  },
  setup(props) {
    const model = CloudFun.current?.model;
    const $t = CloudFun.current?.i18n.global.t;
    const uploader = ref<{
      previewSrc?: string;
      files: any[];
      upload: () => Promise<any[]>;
      removeAllFile:  () => Promise<any[]>;
    }>({
      files: [],
      upload: () => Promise.resolve([]),
      removeAllFile: ()=> Promise.resolve([]),
    });
    const images = ref<any>(props.modelValue);
    const version = ref(0);
    const loading = ref(false);
    const editingImage = ref({});
    const modalVisible = ref(false);

    return {
      model,
      $t,
      uploader,
      images,
      version,
      loading,
      editingImage,
      modalVisible,
    };
  },
  watch: {
    modelValue(current, original) {
      // console.log("...", current, original);
      this.images = current;
      this.reload(this.images);
    },
  },
  methods: {
    uploaderFilter(current, original, prevent) {
      if (!current) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    updateValue(value, id) {
      this.$emit("update", value, id);
    },
    reload(images) {
      const action = () => {
        this.images = images;
        this.$emit("update:modelValue", this.images);
      };
      if (this.$attrs.onRefresh) this.$emit("refresh");
      else action();
    },
    add() {
      this.editingImage = {};
      this.modalVisible = true;
      const action = async () => {
        this.modalVisible = true;
        while (!("previewSrc" in this.uploader))
          await new Promise<void>((resolve) => {
            setTimeout(() => resolve(), 10);
          });
        this.uploader.previewSrc = undefined;
        this.uploader.files = [];
      };
      if (this.$attrs.onAdd) this.$emit("add", this.editingImage, action);
      else action();
    },
    async edit(image) {
      this.editingImage = image;
      const action = async () => {
        this.modalVisible = true;
        while (!("previewSrc" in this.uploader))
          await new Promise<void>((resolve) => {
            setTimeout(() => resolve(), 10);
          });
        this.uploader.previewSrc = image.Uri;
        this.uploader.files = [];
      };
      if (this.$attrs.onEdit) this.$emit("edit", image, action);
      else action();
    },
    async save(image) {
      console.log('Save');
      const params: { insertRows: any[]; updateRows: any[] } = {
        insertRows: [],
        updateRows: [],
      };

      if (this.uploader.files.length) {
        this.uploader.files.forEach((e) => {
          e.active = true;
        });
        await this.uploader.upload().then((files) => {
          files.forEach((file) => {
            image.Uri = file.response.payload?.length
              ? file.response.payload[0]
              : undefined;
            image.ContentType = file.type;
            if (image.Id) params.updateRows.push({ ...image });
            else params.insertRows.push({ ...image });
          });
        });
      } else if (image.Id) params.updateRows.push({ ...image });
      else {
        alert("請選擇上傳的照片");
        return;
      }
      const action = () => {
        this.$emit("update:modelValue", this.images);
        this.loading = false;
        this.modalVisible = false;
      };
      this.loading = true;
      if (this.$attrs.onSave) this.$emit("save", params, action);
      else action();
    },
    remove(image) {
      if (image && confirm("確定要進行刪除嗎?")) {
        const action = () => {
          const index = this.images.indexOf(image);
          if (index >= 0) this.images.splice(index, 1);
          this.$emit("update:modelValue", this.images);
        };
        if (this.$attrs.onRemove) this.$emit("remove", image, action);
        else action();
      }
    },
    hideModal() {
      console.log('close');
      this.modalVisible = false;
    }
  },
});
