import { ModelState } from "@cloudfun/core";
import { Module } from "vuex";

type Paing = {
  currentPage: number;
  pageSize: number;
};

interface State {
  entry: "service" | "merchandise";
  merchandisePaging: Paing;
  servicePaging: Paing;
  detailItemPaging: Paing;
}

const module: Module<State, ModelState> = {
  namespaced: true,
  state: {
    entry: "service",
    merchandisePaging: {
      currentPage: 1,
      pageSize: 10,
    },
    servicePaging: {
      currentPage: 1,
      pageSize: 10,
    },
    detailItemPaging: {
      currentPage: 1,
      pageSize: 10,
    },
  },
  getters: {
    entry: (state) => state.entry,
    merchandisePaging: (state) => state.merchandisePaging,
    servicePaging: (state) => state.servicePaging,
    detailItemPaging: (state) => state.detailItemPaging,
  },
  mutations: {
    setEntry(state, entry) {
      state.entry = entry;
    },
    setMerchandisePaging(state, paging: Paing) {
      if (paging.currentPage)
        state.merchandisePaging.currentPage = paging.currentPage;
      if (paging.pageSize) state.merchandisePaging.pageSize = paging.pageSize;
    },
    setServicePaging(state, paging: Paing) {
      if (paging.currentPage)
        state.servicePaging.currentPage = paging.currentPage;
      if (paging.pageSize) state.servicePaging.pageSize = paging.pageSize;
    },
    setDetailItemPaging(state, paging: Paing) {
      if (paging.currentPage)
        state.detailItemPaging.currentPage = paging.currentPage;
      if (paging.pageSize) state.detailItemPaging.pageSize = paging.pageSize;
    },
    reset(state) {
      state.entry = "service";
      state.merchandisePaging = {
        currentPage: 1,
        pageSize: 10,
      };
      state.servicePaging = {
        currentPage: 1,
        pageSize: 10,
      };
      state.detailItemPaging = {
        currentPage: 1,
        pageSize: 10,
      };
    },
  },
  actions: {
    setEntry({ commit }, data) {
      commit("setEntry", data);
    },
    setMerchandisePaging({ commit }, data) {
      commit("setMerchandisePaging", data);
    },
    setServicePaging({ commit }, data) {
      commit("setServicePaging", data);
    },
    setDetailItemPaging({ commit }, data) {
      commit("setDetailItemPaging", data);
    },
    reset({ commit }) {
      commit("reset");
    },
  },
};

export default module;
