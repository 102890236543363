import { computed, reactive } from "vue";

export interface CfCollapseGroupState {
  accordion: boolean;
  panelExpandStatus: Record<string, boolean>;
}

export const toggleIcon = `
    <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6.34292 7.75734L4.92871 9.17155L11.9998 16.2426L19.0708 9.17158L17.6566 7.75737L11.9998 13.4142L6.34292 7.75734Z"/>
    </svg>`;

const normalizedPartialColor = (partial: number): number => {
  if (partial > 255) return 255;
  if (partial < 0) return 0;
  return partial;
};

export const lightenDarkenColor = (hexColor: string, amount: number) => {
  const color = hexColor.replace("#", "");
  const colorNumber = parseInt(color, 16);

  const red = normalizedPartialColor((colorNumber >> 16) + amount);
  const blue = normalizedPartialColor(((colorNumber >> 8) & 0x00ff) + amount);
  const green = normalizedPartialColor((colorNumber & 0x0000ff) + amount);

  return "#" + (green | (blue << 8) | (red << 16)).toString(16);
};

const state = reactive({
  panelGroups: {} as Record<string, CfCollapseGroupState>,
});

export const useCollapsiblePanelStore = () => {
  const useGroupAccordion = (idGroup: string) =>
    computed(() => state.panelGroups[idGroup].accordion);

  const setGroupAccordionStatus = (idGroup: string, status: boolean) => {
    state.panelGroups[idGroup] = {
      ...state.panelGroups[idGroup],
      accordion: status,
    };
  };

  const panelExpanded = (idGroup: string, idPanel: string) =>
    computed(
      () => state.panelGroups[idGroup]?.panelExpandStatus?.[idPanel] || false
    );

  const setPanelExpandedStatus = (
    idGroup: string,
    idPanel: string,
    status: boolean
  ) => {
    state.panelGroups[idGroup] = {
      ...state.panelGroups[idGroup],
      panelExpandStatus: {
        ...(state.panelGroups[idGroup]?.panelExpandStatus || {}),
        [idPanel]: status,
      },
    };
  };

  const togglePanelExpandedStatus = (
    idGroup: string,
    idPanel: string
  ): void => {
    if (!state.panelGroups[idGroup].accordion) {
      state.panelGroups[idGroup].panelExpandStatus[idPanel] =
        !state.panelGroups[idGroup].panelExpandStatus[idPanel];
      return;
    }

    if (!state.panelGroups[idGroup].panelExpandStatus[idPanel]) {
      Object.keys(state.panelGroups[idGroup].panelExpandStatus).forEach(
        (key) => {
          state.panelGroups[idGroup].panelExpandStatus[key] = false;
        }
      );
    }

    state.panelGroups[idGroup].panelExpandStatus[idPanel] =
      !state.panelGroups[idGroup].panelExpandStatus[idPanel];
  };

  return {
    useGroupAccordion,
    setGroupAccordionStatus,
    panelExpanded,
    setPanelExpandedStatus,
    togglePanelExpandedStatus,
  };
};
