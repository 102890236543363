import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-009f30c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vcp__header-title" }
const _hoisted_2 = {
  key: 0,
  class: "vcp__header-icon"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  ref: "bodyRef",
  class: "vcp__body"
}
const _hoisted_5 = {
  ref: "bodyContentRef",
  class: "vcp__body-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", {
    ref: "panelRef",
    class: _normalizeClass(["vcp", {
      'vcp--expanded': _ctx.isExpanded,
      'vcp--expandable': _ctx.body.hasContent,
    }])
  }, [
    _createElementVNode("header", {
      class: "vcp__header",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "title", {}, undefined, true)
      ]),
      (_ctx.body.hasContent)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "icon", {}, () => [
              _createElementVNode("span", { innerHTML: _ctx.toggleIcon }, null, 8, _hoisted_3)
            ], true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_Transition, {
      "data-key": _ctx.body.dataKey,
      name: "slide",
      onBeforeEnter: _ctx.collapse,
      onEnter: _ctx.expand,
      onBeforeLeave: _ctx.expand,
      onLeave: _ctx.collapse
    }, {
      default: _withCtx(() => [
        (_ctx.isExpanded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "content", {}, undefined, true)
              ], 512)
            ], 512))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["data-key", "onBeforeEnter", "onEnter", "onBeforeLeave", "onLeave"])
  ], 2))
}