/* eslint-disable */
import { deepEqual } from "fast-equals";
import { ModelState, IJsonResponse, IUserData } from "@cloudfun/core";
import { Module } from "vuex";
import { generateHeaders } from "@/utils";

const MAX_ALIVE_TIME = 15000;
const apiPath = "/Employees";

interface State {
  loadingTime?: Date;
  params: any;
  totalCount: number;
  rows: any[];
}

const module: Module<State, ModelState> = {
  namespaced: true,
  state: {
    params: {},
    totalCount: 0,
    rows: [],
  },
  getters: {
    isExpired: (state) =>
      !state.loadingTime ||
      new Date().valueOf() - state.loadingTime.valueOf() < MAX_ALIVE_TIME,
    page: (state) => state.params.page,
    pageSize: (state) => state.params.pageSize,
    condition: (state) => state.params.condition,
    sortings: (state) => state.params.sortings,
    totalCount: (state) => state.totalCount,
    rows: (state) => state.rows,
  },
  mutations: {
    setPage(state, page) {
      if (state.params.page == page) return;
      state.params.page = page;
      state.loadingTime = undefined;
    },
    setPageSize(state, pageSize) {
      if (state.params.pageSize == pageSize) return;
      state.params.pageSize = pageSize;
      state.loadingTime = undefined;
    },
    setCondition(state, condition) {
      if (deepEqual(state.params.condition, condition)) return;
      state.params.condition = condition;
      state.loadingTime = undefined;
    },
    setSortings(state, sortings) {
      if (deepEqual(state.params.sortings, sortings)) return;
      state.params.sortings = sortings;
      state.loadingTime = undefined;
    },
    setParams(state, params) {
      if (deepEqual(state.params, params)) return;
      state.params = params;
      state.loadingTime = undefined;
    },
    setRows(state, rows) {
      state.loadingTime = new Date();
      state.rows = rows;
    },
    setTotalCount(state, totalCount) {
      state.loadingTime = new Date();
      state.totalCount = totalCount;
    },
    setLoadingTime: (state, time) => (state.loadingTime = time),
  },
  actions: {
    find: ({ rootState, state, getters }, key) =>
      new Promise<any>((resolve, reject) => {
        rootState.clients.authorized.get(`${apiPath}/${key}`, { headers: generateHeaders(rootState) }).then(
          (success: IJsonResponse<any>) => resolve(success.payload!),
          (failure: IJsonResponse<any>) => reject(failure.message)
        );
      }),
    findStylist: ({ rootState, state, getters }, key) =>
      new Promise<any>((resolve, reject) => {
        rootState.clients.authorized.get(`${apiPath}/FindStylist/${key}`, { headers: generateHeaders(rootState) }).then(
          (success: IJsonResponse<any>) => resolve(success.payload!),
          (failure: IJsonResponse<any>) => reject(failure.message)
        );
      }),
    // insert: ({ rootState, commit }, data) =>
    //   new Promise<any>((resolve, reject) => {
    //     rootState.clients.authorized.post(apiPath, data).then(
    //       (success: IJsonResponse<any>) => {
    //         commit("setLoadingTime", null); // reset loading time
    //         resolve(success.payload!);
    //       },
    //       (failure: IJsonResponse<any>) => reject(failure.message)
    //     );
    //   }),
    // update: ({ rootState, commit }, data) =>
    //   new Promise<any>((resolve, reject) => {
    //     rootState.clients.authorized.put(apiPath, data).then(
    //       (success: IJsonResponse<any>) => {
    //         commit("setLoadingTime", null);
    //         resolve(success.payload!);
    //       },
    //       (failure: IJsonResponse<any>) => reject(failure.message)
    //     );
    //   }),
    // delete: ({ rootState, commit }, key) =>
    //   new Promise((resolve, reject) => {
    //     rootState.clients.authorized.delete(apiPath, { params: { key } }).then(
    //       (success: IJsonResponse<any>) => {
    //         commit("setLoadingTime", null);
    //         resolve(success.payload);
    //       },
    //       (failure: IJsonResponse<any>) => reject(failure.message)
    //     );
    //   }),
    save: ({ rootState, commit }, changes) => {
      if(changes.updateRows || changes.insertRows) { //insert 與 update 權限、雇用資料parse
        var row = changes.updateRows.length > 0 ? changes.updateRows[0] : changes.insertRows[0];
        changes.PositionIds = row.PositionList;
        if(changes.updateRows.length > 0) {
          changes.updateRows[0].Positions = row.PositionList.map(e => { return { PositionId: e, EmployeeId: row.Id }});   
          var Employment = changes.updateRows[0].Employments.find(e => e.Status != 2);       
          if(Employment) {
            Employment.Permissions = row.PermissionList.map(e => { return { Id: e }});  
            //console.log(changes.updateRows[0].Employments.find(e => e.Status != 2).Permissions)   
          }     
        }
        if(changes.insertRows.length > 0) {
          changes.insertRows[0].Employments = [];
          changes.insertRows[0].Employments.push(row.Employment);          
        }
      }
      //console.log(changes);
      return new Promise((resolve, reject) => {
        rootState.clients.authorized.post(`${apiPath}/Save`, changes, { headers: generateHeaders(rootState) }).then(
          (success: IJsonResponse<any>) => {
            commit("setLoadingTime", null);
            resolve(success.payload);
          },
          (failure: IJsonResponse<any>) => { return reject(failure.payload ?? failure) }
        );
      })
    },
    query: ({ rootState }, params) =>
      new Promise((resolve, reject) => {
        rootState.clients.authorized.get(apiPath, { params, headers: generateHeaders(rootState) }).then(
          (success: IJsonResponse<any>) => resolve(success.payload),
          (failure: IJsonResponse<any>) => reject(failure.message)
        );
      }),
    options: ({ rootState }, params) =>
      new Promise((resolve, reject) => {
        rootState.clients.authorized
          .get(`${apiPath}/Options`, {
            params,
            headers: generateHeaders(rootState),
          })
          .then(
            (success: IJsonResponse<any>) => resolve(success.payload),
            (failure: IJsonResponse<any>) => reject(failure.message)
          );
      }),
    load: ({ rootState, state, commit }, params = null) =>
      new Promise((resolve, reject) => {
        if (params) commit("setParams", params);
        params = { ...state.params };
        console.log("send params: ", JSON.stringify(params));
        rootState.clients.authorized.get(apiPath, { params, headers: generateHeaders(rootState) }).then(
          (success: IJsonResponse<any>) => {
            commit("setLoadingTime", new Date());
            commit("setTotalCount", success.payload.totalCount);
            commit("setRows", success.payload.data);
            console.log("load", success.payload);
            resolve(success.payload);
          },
          (failure: IJsonResponse<any>) => reject(failure.message)
        );
      }),
    incumbents: ({ rootState }, params) =>
      new Promise((resolve, reject) => {
        rootState.clients.authorized
          .get(`${apiPath}/Incumbents`, { params, headers: generateHeaders(rootState) })
          .then(
            (success: IJsonResponse<any>) => resolve(success.payload),
            (failure: IJsonResponse<any>) => reject(failure.message)
          );
      }),
    updateStatus: ({ rootState }, params) => new Promise((resolve, reject) => {
      rootState.clients.authorized.post(`${apiPath}/UpdateStatus`, params, { headers: generateHeaders(rootState) }).then(
        (success: IJsonResponse<any>) => resolve(success.payload),
        (failure: IJsonResponse<any>) => reject(failure.message)
      )
    }),
    changePassword: ({ rootState }, params) => new Promise((resolve, reject) => {
      rootState.clients.authorized.post(`${apiPath}/ChangePassword`, params, { headers: generateHeaders(rootState) }).then(
        (success: IJsonResponse<any>) => resolve(success.payload),
        (failure: IJsonResponse<any>) => reject(failure.message)
      )
    }),
    // uploadPicture: ({ rootState, commit }, data) =>
    //   new Promise<any>((resolve, reject) => {
    //     rootState.clients.authorized
    //       .post(
    //         `${apiPath}/UploadPicture?id=${data.id ?? ""}&type=${data.type}`,
    //         data.formData
    //       )
    //       .then(
    //         (success: IJsonResponse<any>) => {
    //           commit("setLoadingTime", null); // reset loading time
    //           resolve(success.payload!);
    //         },
    //         (failure: IJsonResponse<any>) => reject(failure.message)
    //       );
    //   }),
  },
};

export default module;
