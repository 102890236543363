
import { v4 as uuid } from "uuid";
import { defineComponent, ref } from "vue";
import { useCollapsiblePanelStore, lightenDarkenColor } from "./index";
export default defineComponent({
  name: "CfCollapseGroup",
  props: {
    accordion: {
      type: Boolean,
      default: false,
    },
    baseColor: {
      type: String,
      default: "#333333",
    },
  },
  setup(props) {
    const idGroup = ref(`group-${uuid()}`);
    const { setGroupAccordionStatus } = useCollapsiblePanelStore();
    const cssColorVars = {
      "--base-color": props.baseColor,
      "--border-color": lightenDarkenColor(props.baseColor, 160),
      "--bg-color-header": lightenDarkenColor(props.baseColor, 170),
      "--bg-color-header-hover": lightenDarkenColor(props.baseColor, 175),
      "--bg-color-header-active": lightenDarkenColor(props.baseColor, 170),
      "--bg-color-body": "#fff",
    };
    setGroupAccordionStatus(idGroup.value, props.accordion);
    return {
      idGroup,
      cssColorVars,
    };
  },
});
