<template>
  <div class="breadcrumb">
    <div
      class="breadcrumb-item"
      v-for="(node, index) in $breadcrumb"
      :key="`breadcrumb-${index}`"
    >
      <span
        v-if="$breadcrumb.length === index + 1"
        class="breadcrumb-item active"
        >{{ $t(node.title) }}</span
      >
      <router-link v-else-if="node.to" :to="node.to" class="">{{
        $t(node.title)
      }}</router-link>
      <span v-else class="breadcrumb-item">{{ $t(node.title) }}</span>
      <i v-if="$breadcrumb.length === index + 1" class="breadcrumb__icon" />
    </div>
  </div>
</template>
