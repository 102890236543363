type RFSType =
  | "webkitRequestFullScreen"
  | "requestFullscreen"
  | "msRequestFullscreen"
  | "mozRequestFullScreen";
type EFSType =
  | "webkitExitFullscreen"
  | "msExitFullscreen"
  | "mozCancelFullScreen"
  | "exitFullscreen";
type FSEType =
  | "webkitFullscreenElement"
  | "msFullscreenElement"
  | "mozFullScreenElement"
  | "fullscreenElement";

let defaultRFS: RFSType = "requestFullscreen";
let defaultEFS: EFSType = "exitFullscreen";
let defaultFSE: FSEType = "fullscreenElement";
const docEl = document.documentElement;

if (`webkitRequestFullScreen` in docEl) {
  defaultRFS = "webkitRequestFullScreen";
  defaultEFS = "webkitExitFullscreen";
  defaultFSE = "webkitFullscreenElement";
} else if (`msRequestFullscreen` in docEl) {
  defaultRFS = "msRequestFullscreen";
  defaultEFS = "msExitFullscreen";
  defaultFSE = "msFullscreenElement";
} else if (`mozRequestFullScreen` in docEl) {
  defaultRFS = "mozRequestFullScreen";
  defaultEFS = "mozCancelFullScreen";
  defaultFSE = "mozFullScreenElement";
} else if (!(`requestFullscreen` in docEl)) {
  throw `Current browser does not support Fullscreen API !`;
}

export const beFull = (options?: FullscreenOptions): Promise<void> =>
  docEl[defaultRFS](options);

export const existFull = (): Promise<void> => document[defaultEFS]();

export const isFull = (): boolean => !!document[defaultFSE];

export const toggleFullscreen = (options?: FullscreenOptions): Promise<void> =>
  isFull() ? existFull() : beFull(options);
