/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Chart from "./chart/Main.vue";
import LoadingIcon from "./loading-icon/Main.vue";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import { Tabs, Tab, TabPanels, TabPanel } from "vue3-tabs";
import FontAwesomeIcon from "./font-awesome";

import {
  CfCollapse,
  CfCollapseGroup,
} from "../cloudfun/components/collapse/install";
import CfDataGrid from "../cloudfun/components/CfDataGrid.vue";
import CfSlideEditor from "../cloudfun/components/CfSlideEditor.vue";
import { CfCol, CfContainer, CfRow } from "../cloudfun/components/layouts";

export default (app: any): void => {
  app.component("Chart", Chart);
  app.component("LoadingIcon", LoadingIcon);
  app.component("PerfectScrollbar", PerfectScrollbar);
  app.component("Tabs", Tabs);
  app.component("Tab", Tab);
  app.component("TabPanels", TabPanels);
  app.component("TabPanel", TabPanel);
  app.component("FontAwesome", FontAwesomeIcon);
  app.component("CfCollapse", CfCollapse);
  app.component("CfCollapseGroup", CfCollapseGroup);
  app.component("CfDataGrid", CfDataGrid);
  app.component("CfSlideEditor", CfSlideEditor);
  app.component("CfCol", CfCol);
  app.component("CfContainer", CfContainer);
  app.component("CfRow", CfRow);
};
