/* eslint-disable */

import { deepEqual } from "fast-equals";
import { ModelState, IJsonResponse, IUserData } from "@cloudfun/core";
import { Module } from "vuex";
import XEUtils from "xe-utils";
import type { Employee, Order } from "./data";

interface State {
  data: OrderState;
  dataChanged: boolean;
}

export interface OrderState extends Order {
  Items: OrderStateItem[];
  List: any[];
}

type ItemType = "service" | "product";

export interface OrderStateItem {
  _XID?: string;
  Id: string;
  Name: string;
  Number: string;
  Deleted: boolean;
  EmployeeId?: string;
  EmployeeName?: string;
  Price: number;
  Quantity: number;
  Discount: number;
  DiscountAmount: number;
  Amount: number;
  NoDiscount: boolean;
  Ordinal: number;
  Mixed: boolean;
  Type: ItemType;
  ParentId?: string;
  DesignPoint: number;
  OperatingPoint: number;
  MarkingPoint: number;
  Children?: OrderStateItem[];
}

const module: Module<State, ModelState> = {
  namespaced: true,
  state: {
    data: {} as OrderState,
    dataChanged: false,
  },
  getters: {
    data: (state) => state.data,
  },
  mutations: {
    set(state, payload) {
      state.data = payload;
    },
    addItem(
      state,
      payload: {
        data: any;
        type: ItemType;
        discount: number;
      }
    ) {
      const data = payload.data;
      const type = payload.type;
      const discountRate =
        payload.discount && !data.NoDiscount ? payload.discount : 1;

      const item: OrderStateItem = {
        Id: data.Id,
        Name: data.Name,
        Number: data.Number,
        Price: data.SalePrice,
        Discount: discountRate,
        Amount: data.SalePrice,
        Quantity: 1,
        DiscountAmount: 0,
        Ordinal: 0,
        Mixed: data.Mixed,
        Deleted: false,
        Type: type,
        NoDiscount: data.NoDiscount,
        EmployeeId: state.data.EmployeeId,
        EmployeeName: state.data.EmployeeName,
        DesignPoint: data.DesignPoint,
        OperatingPoint: 0,
        MarkingPoint: data.MarkingPoint,
        Children: [],
      };

      let hasChild = false;
      if (data.Items && data.Items.length > 0) {
        data.Items.forEach((child) => {
          const itemDiscount =
            payload.discount && !child.NoDiscount ? payload.discount : 1;
          item.Children?.push({
            Id: child.ServiceId,
            Name: child.ServiceName,
            Number: child.Number,
            Price: 0,
            Quantity: child.Quantity < 1 ? 1 : child.Quantity,
            Discount: itemDiscount,
            DiscountAmount: 0,
            NoDiscount: false,
            Amount: 0,
            Ordinal: 0,
            Type: type,
            Mixed: child.Mixed,
            Deleted: false,
            ParentId: item.Id,
            DesignPoint: 0,
            OperatingPoint: child.OperatingPoint,
            MarkingPoint: child.MarkingPoint,
            EmployeeId: "",
            EmployeeName: "",
          });
        });

        hasChild = true;
      }
      state.data.Items.push(item);
      state.data.Items = XEUtils.sortBy(state.data.Items, "Type").reverse();
    },
    removeItem(state, id) {
      state.data.Items.forEach((element, index) => {
        if (element._XID === id) {
          if (element.Id) {
            element.Deleted = true;
            if (element.Children) {
              element.Children.forEach((item) => {
                item.Deleted = true;
              });
            }
          } else state.data.Items.splice(index, 1);
          return false;
        }
      });
    },
    calculate(state, useList) {
      if (state.data && state.data.Items) {
        var list = useList ? state.data.List : state.data.Items
        list.forEach((item) => {
          item.DiscountAmount =
            -1 * (item.Price - Math.round(item.Price * item.Discount));
          item.Amount = item.Price * item.Quantity + item.DiscountAmount;
        });

        state.data.SaleAmount = list
          ? XEUtils.sum(
            list.filter((item) => item.Deleted != true).map(
                (row) => row.Price * row.Quantity
              )
            )
          : 0;

        state.data.DiscountAmount = list
          ? XEUtils.sum(
              list.filter((item) => item.Deleted != true).map(
                (row) => row.DiscountAmount
              )
            )
          : 0;

        state.data.Amount = state.data.SaleAmount + state.data.DiscountAmount;
      }
    },
    totalDiscount(state, discount) {
      state.data.Items.forEach((element) => {
        if (!element.NoDiscount) {
          element.Discount = discount / 100;
          element.DiscountAmount =
            -1 * (element.Price - element.Price * element.Discount);
          element.Amount = element.Price + element.DiscountAmount;
        }
      });
    },
    discountList(state, discount) {
      console.log( discount)
      state.data.List.forEach((element) => {
        if (!element.NoDiscount) {
          element.Discount = discount / 100;
          element.DiscountAmount =
            -1 * (element.Price - element.Price * element.Discount);
          element.Amount = element.Price + element.DiscountAmount;
        }
      });
    },
    changeEmployee(state, employee: Employee) {
      if (employee && state.data.Items) {
        state.data.Items.forEach((e) => {
          e.EmployeeId = employee.Id;
          e.EmployeeName = employee.Nickname;
          e.Children?.forEach((c) => {
            delete c.EmployeeId;
            delete c.EmployeeName;
          });
        });
      }
    },
    reset(state) {
      state.data = {} as OrderState;
    },
    dataChanged(state, dataChanged: boolean) {
      state.dataChanged = dataChanged;
    },
  },
  actions: {
    set({ commit }, payload) {
      commit("set", payload);
      commit("dataChanged", true);
    },
    addItem({ commit }, payload) {
      commit("addItem", payload);
      commit("calculate", false);
      commit("dataChanged", true);
    },
    removeItem({ commit }, id) {
      commit("removeItem", id);
      commit("calculate", false);
      commit("dataChanged", true);
    },
    totalDiscount({ commit }, discount) {
      commit("totalDiscount", discount);
      commit("calculate", false);
    },
    discountList({ commit }, discount) {
      commit("discountList", discount);
      commit("calculate", true);
    },
    calculate({ commit }) {
      commit("calculate", true);
    },
    changeEmployee({ commit }, employee) {
      commit("changeEmployee", employee);
      commit("dataChanged", true);
    },
    reset({ commit }) {
      commit("reset");
      commit("dataChanged", false);
    },
  },
};

export default module;
