import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import AppLayout from "./layouts/app-layout/Main.vue";

const basisRoutes: RouteRecordRaw[] = [
  {
    path: "/basis/store/info",
    component: () => import("./views/basis/store/Main.vue"),
    meta: { rule: "StoreRead" },
  },
  {
    path: "/basis/store/position",
    component: () => import("./views/basis/store/Position.vue"),
    meta: { rule: "PositionRead" },
  },
  {
    path: "/basis/store/partition",
    component: () => import("./views/basis/store/Partition.vue"),
    meta: { rule: "PartitionRead" },
  },
  {
    path: "/basis/store/equipment",
    component: () => import("./views/basis/store/Equipment.vue"),
    meta: { rule: "SupportableFacilityRead" },
  },
  {
    path: "/basis/store/printer",
    component: () => import("./views/basis/store/Printer.vue"),
    meta: { rule: "PrinterSettingRead" },
  },
  {
    path: "/basis/merchandise/category",
    component: () => import("./views/basis/merchandise/Category.vue"),
    meta: { rule: "ProductCategoryRead,ServiceCategoryRead" },
  },
  {
    path: "/basis/merchandise/productCategory",
    component: () => import("./views/basis/merchandise/ProductCategory.vue"),
    meta: { rule: "ProductCategoryRead" },
  },
  {
    path: "/basis/merchandise/category/:categoryId/services",
    component: () => import("./views/basis/merchandise/Service.vue"),
    meta: { rule: "ServiceRead" },
  },
  {
    path: "/basis/merchandise/category/:categoryId/services/:itemId",
    component: () => import("./views/basis/merchandise/ServiceCompose.vue"),
    meta: { rule: "ServiceComposeRead" },
  },
  {
    path: "/basis/merchandise/category/:categoryId/merchandises",
    component: () => import("./views/basis/merchandise/Merchandise.vue"),
    meta: { rule: "ProductRead" },
  },
  {
    path: "/basis/merchandise/category/:categoryId/merchandises/:itemId",
    component: () => import("./views/basis/merchandise/MerchandiseCompose.vue"),
    meta: { rule: "ProductComposeRead" },
  },
  {
    path: "/basis/merchandise/inventory",
    component: () => import("./views/basis/merchandise/Inventory.vue"),
    // meta: { rule: "InventoryRead" },
  },
  {
    path: "/basis/merchandise/service-item",
    component: () => import("./views/basis/merchandise/ServiceItem.vue"),
    meta: { rule: "ServiceCategoryRead" },
  },
  {
    path: "/basis/merchandise/material",
    component: () => import("./views/basis/merchandise/Material.vue"),
    meta: { rule: "ServiceCategoryRead" },
  },
  {
    path: "/basis/merchandise/material-category",
    component: () => import("./views/basis/merchandise/MaterialCategory.vue"),
    // meta: { rule: "ProductRead" },
  },
  // {
  //   path: "/basis/merchandise/material/:categoryId/:categoryName",
  //   component: () => import("./views/basis/merchandise/material/Items.vue"),
  //   meta: { rule: "ProductRead" },
  // },
  {
    path: "/basis/invoicing/supplier",
    component: () => import("./views/basis/Supplier.vue"),
    // meta: { rule: "SupplierRead" },
  },
  {
    path: "/basis/skill",
    component: () => import("./views/basis/Skill.vue"),
    meta: { rule: "SkillRead" },
  },
  {
    path: "/basis/grade/customer",
    component: () => import("./views/basis/CustomerGrade.vue"),
    meta: { rule: "CustomerGradeRead" },
  },
  {
    path: "/basis/invoicing/material",
    component: () => import("./views/basis/invoicing/material/Main.vue"),
  },
  {
    path: "/basis/invoicing/material-record",
    component: () => import("./views/basis/invoicing/material-record/Main.vue"),
  },
  {
    path: "/basis/invoicing/material-request",
    component: () =>
      import("./views/basis/invoicing/material-request/Main.vue"),
  },
  {
    path: "/basis/invoicing/procurement",
    component: () => import("./views/basis/invoicing/procurement/Main.vue"),
  },
  {
    path: "/basis/invoicing/inspection",
    component: () => import("./views/basis/invoicing/inspection/Main.vue"),
  },
  {
    path: "/basis/invoicing/picking",
    component: () => import("./views/basis/invoicing/picking/Main.vue"),
  },
  //   {
  //     path: "invoicing",
  //     redirect: "invoicing/material-request",
  //     meta: { rule: "SupplierRead" },
  //     children: [
  //       {
  //         path: "material",
  //         component: () => import("./views/basis/invoicing/material/Main.vue"),
  //       },
  //       {
  //         path: "material-record",
  //         component: () =>
  //           import("./views/basis/invoicing/material-record/Main.vue"),
  //       },
  //       {
  //         path: "procurement",
  //         component: () => import("./views/basis/invoicing/procurement/Main.vue"),
  //       },
  // {
  //   path: "/basis/invoicing/inspection",
  //   component: () => import("./views/basis/invoicing/inspection/Main.vue"),
  // },
  {
    path: "/basis/invoicing/purchase",
    component: () => import("./views/basis/invoicing/purchase/Main.vue"),
  },
  {
    path: "/basis/invoicing/purchase-return",
    component: () => import("./views/basis/invoicing/purchase-return/Main.vue"),
  },
  {
    path: "/basis/invoicing/inventory",
    component: () => import("./views/basis/invoicing/inventory/Main.vue"),
  },
  {
    path: "/basis/invoicing/inventory-record",
    component: () => import("./views/basis/invoicing/inventory/Record.vue"),
  },
  //     ],
  //   },
  {
    path: "/report/revenue-flow",
    component: () => import("./views/report/RevenueFlow.vue"),
  },
  {
    path: "/report/revenue",
    component: () => import("./views/report/Revenue.vue"),
  },
  {
    path: "/report/performance",
    component: () => import("./views/report/Performance.vue"),
  },
  {
    path: "/report/age-group",
    component: () => import("./views/report/AgeGroup.vue"),
  },
  {
    path: "/report/salary",
    component: () => import("./views/report/Salary.vue"),
  },
  {
    path: "/report/employee-sales",
    component: () => import("./views/report/EmployeeSales.vue"),
  },
  {
    path: "/report/merchandise-sales",
    component: () => import("./views/report/MerchandiseSales.vue"),
  },
];

// const reportRoute: RouteRecordRaw = {
//   path: "report",
//   redirect: "report/revenue-flow",
//   children: [
//     {
//       path: "revenue-flow",
//       component: () => import("./views/report/RevenueFlow.vue"),
//     },
//     {
//       path: "revenue",
//       component: () => import("./views/report/Revenue.vue"),
//     },
//     {
//       path: "performance",
//       component: () => import("./views/report/Performance.vue"),
//     },
//     {
//       path: "age-group",
//       component: () => import("./views/report/AgeGroup.vue"),
//     },
//     {
//       path: "salary",
//       component: () => import("./views/report/Salary.vue"),
//     },
//     {
//       path: "employee-sales",
//       component: () => import("./views/report/EmployeeSales.vue"),
//     },
//     {
//       path: "merchandise-sales",
//       component: () => import("./views/report/MerchandiseSales.vue"),
//     },
//   ],
// };

const routes: RouteRecordRaw[] = [
  {
    path: "/login",
    component: () =>
      import(/* webpackChunkName: "user" */ "./views/identity/Login.vue"),
  },
  {
    path: "/",
    component: AppLayout,
    // redirect: "employee/calendar/daily",
    children: [
      // {
      //   path: "",
      //   component: () => import("./views/Home.vue"),
      // },
      ...basisRoutes,
      {
        path: "",
        component: () => import("./views/employee/calendar/DailySchedule.vue"),
        meta: { rule: "DailyCalendarRead" },
      },
      {
        path: "employee/info",
        component: () => import("./views/employee/Main.vue"),
        meta: { rule: "EmployeeRead" },
      },
      {
        path: "employee/platform",
        component: () => import("./views/employee/Platform.vue"),
        meta: { rule: "PlatformRead" },
      },
      {
        path: "employee/calendar/daily",
        component: () => import("./views/employee/calendar/DailySchedule.vue"),
        // meta: { rule: "" },
      },
      {
        path: "employee/calendar/weekly",
        component: () => import("./views/employee/calendar/WeeklySchedule.vue"),
        // meta: { rule: "" },
      },
      // {
      //   path: "employee/calendar/monthly",
      //   component: () => import("./views/employee/calendar/MonthlySchedule.vue"),
      //   // meta: { rule: "" },
      // },
      {
        path: "employee/calendar/leave",
        component: () => import("./views/employee/calendar/LeaveSchedule.vue"),
        // meta: { rule: "" },
      },
      {
        path: "/basis/supplier",
        component: () => import("./views/basis/Supplier.vue"),
        meta: { rule: "SupplierRead" },
      },
      // {
      //   path: "employee",
      //   redirect: "employee/info",
      //   children: [
      //     // {
      //     //   path: "info",
      //     //   component: () => import("./views/employee/Main.vue"),
      //     //   meta: { rule: "EmployeeRead" },
      //     // },
      //     {
      //       path: "calendar",
      //       redirect: "calendar/daily",
      //       children: [
      // {
      //   path: "/employee/calendar/daily",
      //   component: () =>
      //     import("./views/employee/calendar/DailySchedule.vue"),
      //   // meta: { rule: "DailyCalendarRead" },
      // },
      // {
      //   path: "/employee/calendar/weekly",
      //   component: () =>
      //     import("./views/employee/calendar/WeeklySchedule.vue"),
      //   // meta: { rule: "WeeklyCalendarRead" },
      // },
      // {
      //   path: "/employee/calendar/monthly",
      //   component: () =>
      //     import("./views/employee/calendar/MonthlySchedule.vue"),
      //   // meta: { rule: "MonthlyCalendarRead" },
      // },
      // {
      //   path: "/employee/calendar/leave",
      //   component: () =>
      //     import("./views/employee/calendar/LeaveSchedule.vue"),
      //   // meta: { rule: "LeaveCalendarRead" },
      // },
      //       ],
      //     },
      //     // {
      //     //   path: "platform",
      //     //   component: () => import("./views/employee/Platform.vue"),
      //     //   meta: { rule: "PlatformRead" },
      //     // },
      //   ],
      // },
      {
        path: "customer",
        component: () => import("./views/customer/Main.vue"),
        meta: { rule: "CustomerRead" },
      },
      {
        path: "order-list",
        component: () => import("./views/order/Main.vue"),
        meta: { rule: "OrderRead" },
      },
      {
        path: "order-record",
        component: () => import("./views/order/Record.vue"),
        meta: { rule: "OrderRead" },
      },
      // reportRoute,
    ],
  },
  {
    path: "/error",
    component: () =>
      import(/* webpackChunkName: "error" */ "./views/Error.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    component: () =>
      import(/* webpackChunkName: "error" */ "./views/Error.vue"),
  },
];

export default createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});
