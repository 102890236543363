import { ModelState, IJsonResponse, IUserData } from "@cloudfun/core";
import { Module } from "vuex";

const apiPath = "/Prints";

const module: Module<ModelState, ModelState> = {
  namespaced: true,
  actions: {
    execute: ({ rootState, state, getters }, key) =>
      new Promise<unknown>((resolve, reject) => {
        rootState.clients.authorized.get(`${apiPath}/${key}`).then(
          (success: IJsonResponse<unknown>) => resolve(success.payload),
          (failure: IJsonResponse<string>) => reject(failure.message)
        );
      }),
  },
};

export default module;
