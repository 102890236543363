import {
  defaultColor,
  defaultDirection,
  themeSelectedColorStorageKey,
} from "@/constants/config";

const getDirection = (): { direction: string; isRtl: boolean } => {
  let direction = defaultDirection;
  if (localStorage.getItem("direction")) {
    const value = localStorage.getItem("direction");
    if (value === "rtl" || value === "ltr") {
      direction = value;
    }
  }
  return {
    direction,
    isRtl: direction === "rtl",
  };
};

const setDirection = (value: "rtl" | "ltr" = defaultDirection): void => {
  localStorage.setItem("direction", value);
};

const getThemeColor = (): string =>
  localStorage.getItem(themeSelectedColorStorageKey) || defaultColor;

const setThemeColor = (value: string = defaultColor): void => {
  localStorage.setItem(themeSelectedColorStorageKey, value);
};

export { getDirection, setDirection, getThemeColor, setThemeColor };
