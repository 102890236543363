import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-807b60d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-id-group"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    "data-id-group": _ctx.idGroup,
    style: _normalizeStyle(_ctx.cssColorVars),
    class: "vcpg"
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 12, _hoisted_1))
}