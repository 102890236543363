/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ModelState } from "@cloudfun/core";
import faker from "./faker";
import helper from "./helper";
import lodash from "./lodash";

export function generateHeaders(rootState: ModelState) {
  const headers: any = {};
  if (rootState.currentUser.merchantId) headers.merchant = rootState.currentUser.merchantId;
  if (rootState.currentUser.storeId) headers.store = rootState.currentUser.storeId;
  return headers;
}

export default (app: any): void => {
  app.use(faker);
  app.use(helper);
  app.use(lodash);
};
