/* eslint-disable */

import { App } from "vue";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { toDateString as formatDate, commafy } from "xe-utils";

dayjs.extend(duration);

const helpers = {
  cutText(text: string, length: number): string {
    if (text.split(" ").length > 1) {
      const string = text.substring(0, length);
      const splitText = string.split(" ");
      splitText.pop();
      return splitText.join(" ") + "...";
    } else {
      return text;
    }
  },
  formatDate,
  commafy,
  capitalizeFirstLetter(string: string): string {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return "";
    }
  },
  onlyNumber(string: string): string {
    if (string) {
      return string.replace(/\D/g, "");
    } else {
      return "";
    }
  },
  formatCurrency(number: number): string {
    if (number) {
      const formattedNumber = number.toString().replace(/\D/g, "");
      const rest = formattedNumber.length % 3;
      let currency = formattedNumber.substr(0, rest);
      const thousand = formattedNumber.substr(rest).match(/\d{3}/g);
      let separator;

      if (thousand) {
        separator = rest ? "." : "";
        currency += separator + thousand.join(".");
      }

      return currency;
    } else {
      return "";
    }
  },
  timeAgo(time: string): string | false {
    const date = new Date(
      (time || "").replace(/-/g, "/").replace(/[TZ]/g, " ")
    );
    const diff = (new Date().getTime() - date.getTime()) / 1000;
    const dayDiff = Math.floor(diff / 86400);

    if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
      return dayjs(time).format("MMMM DD, YYYY");
    }

    return (
      (dayDiff === 0 &&
        ((diff < 60 && "just now") ||
          (diff < 120 && "1 minute ago") ||
          (diff < 3600 && Math.floor(diff / 60) + " minutes ago") ||
          (diff < 7200 && "1 hour ago") ||
          (diff < 86400 && Math.floor(diff / 3600) + " hours ago"))) ||
      (dayDiff === 1 && "Yesterday") ||
      (dayDiff < 7 && dayDiff + " days ago") ||
      (dayDiff < 31 && Math.ceil(dayDiff / 7) + " weeks ago")
    );
  },
  diffTimeByNow(time: string): {
    days: string | number;
    hours: string | number;
    minutes: string | number;
    seconds: string | number;
  } {
    const startDate = dayjs(dayjs().format("YYYY-MM-DD HH:mm:ss").toString());
    const endDate = dayjs(dayjs(time).format("YYYY-MM-DD HH:mm:ss").toString());

    const duration = dayjs.duration(endDate.diff(startDate));
    const milliseconds = Math.floor(duration.asMilliseconds());

    const days = Math.round(milliseconds / 86400000);
    const hours = Math.round((milliseconds % 86400000) / 3600000);
    let minutes = Math.round(((milliseconds % 86400000) % 3600000) / 60000);
    const seconds = Math.round(
      (((milliseconds % 86400000) % 3600000) % 60000) / 1000
    );

    if (seconds < 30 && seconds >= 0) {
      minutes += 1;
    }

    return {
      days: days.toString().length < 2 ? "0" + days : days,
      hours: hours.toString().length < 2 ? "0" + hours : hours,
      minutes: minutes.toString().length < 2 ? "0" + minutes : minutes,
      seconds: seconds.toString().length < 2 ? "0" + seconds : seconds,
    };
  },
  isset(obj: Record<string, unknown> | number | string): boolean | number {
    if (obj !== null && obj !== undefined) {
      if (typeof obj === "object" || Array.isArray(obj)) {
        return Object.keys(obj).length;
      } else {
        return obj.toString().length;
      }
    }

    return false;
  },
  toRaw(obj: Record<string, any>): any {
    return JSON.parse(JSON.stringify(obj));
  },
  randomNumbers(from: number, to: number, length: number): Array<number> {
    const numbers = [0];
    for (let i = 1; i < length; i++) {
      numbers.push(Math.ceil(Math.random() * (from - to) + to));
    }

    return numbers;
  },
  getStartTimeOfDay(date) {
    if (!date) {
      return "";
    }

    let parsed = new Date(date);
    // if (!inspect.isDate(parsed)) {
    //   return parsed;
    // }

    return new Date(
      parsed.getFullYear(),
      parsed.getMonth(),
      parsed.getDate(),
      0,
      0,
      0,
      0
    );
  },
  getEndTimeOfDay(date) {
    if (!date) {
      return "";
    }

    let parsed = new Date(date);
    // if (!inspect.isDate(parsed)) {
    //   return parsed;
    // }

    return new Date(
      parsed.getFullYear(),
      parsed.getMonth(),
      parsed.getDate(),
      23,
      59,
      59,
      999
    );
  },
  getFirstDayOfMonth(date) {
    if (!date) {
      return "";
    }

    let parsed = new Date(date);
    // if (!inspect.isDate(parsed)) {
    //   return parsed;
    // }

    return new Date(parsed.getFullYear(), parsed.getMonth(), 1);
  },
  getLastDayOfMonth(date) {
    if (!date) {
      return "";
    }

    let parsed = new Date(date);
    // if (!inspect.isDate(parsed)) {
    //   return parsed;
    // }

    return new Date(
      parsed.getFullYear(),
      parsed.getMonth() + 1,
      0,
      23,
      59,
      59,
      999
    );
  },
  // isArray(obj: any): boolean {
  //   return obj && toString(obj) === "[object Array]";
  // },
  // isDate(obj: any): boolean {
  //   return obj && toString(obj) === "[object Date]";
  // },
  // isEmpty(obj: any): boolean {
  //   return (
  //     obj === null ||
  //     obj === "" ||
  //     obj === 0 ||
  //     (this.isArray(obj) && obj.length === 0) ||
  //     (this.isObject(obj) && Object.keys(obj).length === 0)
  //   );
  // },
  // isObject(obj: any): boolean {
  //   return obj && toString(obj) === "[object Object]";
  // },
  // isFunction(obj: any): boolean {
  //   return obj && toString(obj) === "[object Function]";
  // },
  // isPlainObject(obj: any): boolean {
  //   if (!obj || !this.isObject(obj)) return false;
  //   const proto = getPrototypeOf(obj);
  //   if (!proto) return true;
  //   const ctor = hasOwnProperty(proto, "constructor") && proto.constructor;
  //   return (
  //     typeof ctor === "function" &&
  //     ctor instanceof ctor &&
  //     Function.prototype.toString.call(ctor) ===
  //       Function.prototype.toString.call(Object)
  //   );
  // },
  // isPromise(obj: any): boolean {
  //   return obj && toString(obj) === "[object Promise]";
  // },
  // isValue(obj: any): boolean {
  //   return obj && !this.isArray(obj) && !this.isObject(obj);
  // },
};

const install = (app: App): void => {
  app.config.globalProperties.$h = helpers;
};

export { install as default, helpers as helper };
