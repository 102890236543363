import { defaultMenuType } from "@/constants/config";
import { ModelState, IJsonResponse, IUserData } from "@cloudfun/core";
import { Module } from "vuex";

interface State {
  menuType: string;
  clickCount: number;
  hasSubItems: boolean;
}

const module: Module<State, ModelState> = {
  namespaced: true,
  state: {
    menuType: defaultMenuType,
    clickCount: 0,
    hasSubItems: defaultMenuType === "menu-default",
  },
  getters: {
    menuType: (state) => state.menuType,
    clickCount: (state) => state.clickCount % 4,
    hasSubItems: (state) => state.hasSubItems,
  },
  mutations: {
    changeStatus(state, payload) {
      const { classNames, hasSubItems } = payload;
      let clickIndex = payload.clickIndex;
      const currentClasses = classNames
        ? classNames.split(" ").filter((x: string) => x !== "")
        : "";
      let nextClasses = "";
      if (!hasSubItems) {
        if (
          currentClasses.includes("menu-default") &&
          (clickIndex % 4 === 0 || clickIndex % 4 === 3)
        )
          clickIndex = 1;

        if (currentClasses.includes("menu-sub-hidden") && clickIndex % 4 === 2)
          clickIndex = 0;

        if (
          currentClasses.includes("menu-hidden") &&
          (clickIndex % 4 === 2 || clickIndex % 4 === 3)
        )
          clickIndex = 0;
      }

      if (clickIndex % 4 === 0) {
        if (
          currentClasses.includes("menu-default") &&
          currentClasses.includes("menu-sub-hidden")
        ) {
          nextClasses = "menu-default menu-sub-hidden";
        } else if (currentClasses.includes("menu-default")) {
          nextClasses = "menu-default";
        } else if (currentClasses.includes("menu-sub-hidden")) {
          nextClasses = "menu-sub-hidden";
        } else if (currentClasses.includes("menu-hidden")) {
          nextClasses = "menu-hidden";
        }
        clickIndex = 0;
      } else if (clickIndex % 4 === 1) {
        if (
          currentClasses.includes("menu-default") &&
          currentClasses.includes("menu-sub-hidden")
        ) {
          nextClasses = "menu-default menu-sub-hidden main-hidden sub-hidden";
        } else if (currentClasses.includes("menu-default")) {
          nextClasses = "menu-default sub-hidden";
        } else if (currentClasses.includes("menu-sub-hidden")) {
          nextClasses = "menu-sub-hidden main-hidden sub-hidden";
        } else if (currentClasses.includes("menu-hidden")) {
          nextClasses = "menu-hidden main-show-temporary";
        }
      } else if (clickIndex % 4 === 2) {
        if (
          currentClasses.includes("menu-default") &&
          currentClasses.includes("menu-sub-hidden")
        ) {
          nextClasses = "menu-default menu-sub-hidden sub-hidden";
        } else if (currentClasses.includes("menu-default")) {
          nextClasses = "menu-default main-hidden sub-hidden";
        } else if (currentClasses.includes("menu-sub-hidden")) {
          nextClasses = "menu-sub-hidden sub-hidden";
        } else if (currentClasses.includes("menu-hidden")) {
          nextClasses = "menu-hidden main-show-temporary sub-show-temporary";
        }
      } else if (clickIndex % 4 === 3) {
        if (
          currentClasses.includes("menu-default") &&
          currentClasses.includes("menu-sub-hidden")
        ) {
          nextClasses = "menu-default menu-sub-hidden sub-show-temporary";
        } else if (currentClasses.includes("menu-default")) {
          nextClasses = "menu-default sub-hidden";
        } else if (currentClasses.includes("menu-sub-hidden")) {
          nextClasses = "menu-sub-hidden sub-show-temporary";
        } else if (currentClasses.includes("menu-hidden")) {
          nextClasses = "menu-hidden main-show-temporary";
        }
      }
      if (currentClasses.includes("menu-mobile")) {
        nextClasses += " menu-mobile";
      }
      state.menuType = nextClasses;
      state.clickCount = clickIndex;
      state.hasSubItems = hasSubItems;
    },
    setHasSubItems(state, payload) {
      state.hasSubItems = payload;
    },
    setClassname(state, payload) {
      const { classname, currentClasses } = payload;

      const nextClasses =
        currentClasses.indexOf(classname) > -1
          ? currentClasses + " " + classname
          : currentClasses;
      state.menuType = nextClasses;
    },
    changeForMobile(state, strCurrentClasses) {
      const currentClasses = strCurrentClasses
        ? strCurrentClasses
            .split(" ")
            .filter((x: string) => x !== "" && x !== "sub-show-temporary")
        : "";
      let nextClasses = "";
      if (currentClasses.includes("main-show-temporary")) {
        nextClasses = currentClasses
          .filter((x: string) => x !== "main-show-temporary")
          .join(" ");
      } else {
        nextClasses = currentClasses.join(" ") + " main-show-temporary";
      }

      state.menuType = nextClasses;
      state.clickCount = 0;
    },
  },
};

export default module;
