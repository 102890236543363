import { ModelState } from "@cloudfun/core";
import { Module } from "vuex";

interface State {
  discountPercentages: { label: string; value: any }[];
  internationalPercent: { label: string; value: any }[];
}

let max = 100;
const percentages: { label: string; value: any }[] = [];
const international: { label: string; value: any }[] = [];
while (max >= 0) {
  percentages.push({ label: max + "%", value: max / 100 });
  international.push({ label: max + "%", value: (100 - max) / 100 });
  max -= 5;
}

const module: Module<State, ModelState> = {
  namespaced: true,
  state: {
    discountPercentages: percentages,
    internationalPercent: international,
  },
  getters: {
    discountPercentages: (state) => state.discountPercentages,
    internationalPercent: (state) => state.internationalPercent,
  },
};

export default module;
