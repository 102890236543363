import { Model } from "@cloudfun/core";
import { main, Store as MainStore, State as MainState } from "./main";

import configuration from "./configuration";
import categoryState from "./category-state";
import constant from "./constant";
import customerGrade from "./customer-grade";
import customer from "./customer";
import employeePosition from "./employee-position";
import employee from "./employee";
import employeeEvent from "./employee-event";
import orderState from "./order-state";
import order from "./order";
import partition from "./partition";
import payment from "./payment";
import print from "./print";
import productCategory from "./product-category";
import product from "./product";
import reservationState from "./reservation-state";
import reservation from "./reservation";
import report from "./report";
import review from "./review";
import serviceCategory from "./service-category";
import service from "./service";
import sideMenu from "./side-menu";
import store from "./store";
import stylist from "./stylist";
import supplier from "./supplier";
import inventory from "./inventory";
import inventoryRecord from "./inventory-record";
import serviceItems from "./service-items";
import orderItem from "./order-item";
import position from "./position";
import skill from "./skill";
import supportableFacility from "./supportable-facility";
import paymentMethods from "./payment-methods";
import picture from "./picture";
import pushLog from "./push-log";
import user from "./legacy/user";
import purchaseRequest from "./purchase-request";
import purchaseRequestItem from "./purchase-request-item";
import purchaseOrder from "./purchase-order";
import purchaseOrderItem from "./purchase-order-item";
import purchaseBill from "./purchase-bill";
import purchaseBillItem from "./purchase-bill-item";
import purchaseReturnBill from "./purchase-return-bill";
import purchaseReturnBillItem from "./purchase-return-bill-item";
import saleBill from "./sale-bill";
import saleBillItem from "./sale-bill-item";
import requisitionMemo from "./requisition-memo";
import requisitionMemoItem from "./requisition-memo-item";

const model = new Model("UlameiWebPos", {
  main,
  configuration,
  // role,
  categoryState,
  constant,
  customer,
  customerGrade,
  employeePosition,
  employee,
  employeeEvent,
  orderState,
  order,
  partition,
  payment,
  print,
  productCategory,
  product,
  reservationState,
  reservation,
  report,
  review,
  serviceCategory,
  service,
  sideMenu,
  store,
  stylist,
  supplier,
  inventory,
  inventoryRecord,
  serviceItems,
  orderItem,
  position,
  skill,
  supportableFacility,
  paymentMethods,
  picture,
  pushLog,
  user,
  purchaseRequest,
  purchaseRequestItem,
  purchaseOrder,
  purchaseOrderItem,
  purchaseBill,
  purchaseBillItem,
  purchaseReturnBill,
  purchaseReturnBillItem,
  saleBill,
  saleBillItem,
  requisitionMemo,
  requisitionMemoItem,
});

const backendServer = process.env.VUE_APP_WEB_POS_API;
const reportServer = process.env.VUE_APP_REPORT_API;
const inventoryServer = process.env.VUE_APP_INVENTORY_API;

model.clients.authorized = model.createHttpClient(`${backendServer}/api`, true);
model.clients.unauthorized = model.createHttpClient(`${backendServer}/api`);
model.clients.report = model.createHttpClient(`${reportServer}/api`, true);
model.clients.inventory = model.createHttpClient(
  `${inventoryServer}/api`,
  true
);

model.onLogin = (data) => model.clients.unauthorized.post("System/Login", data);
model.onLogout = () => model.clients.authorized.post("System/Logout");
model.onReloadUser = () =>
  model.clients.authorized.post(
    `System/CurrentUser?sid=${localStorage.getItem("store-id")}`
  );
model.onReloadEnums = () =>
  model.clients.unauthorized.get("System/Enumerations");
model.onLog = (message) =>
  model.clients.unauthorized.post("System/Log", message);

export type State = { main: MainState };
export type Store = MainStore<Pick<State, "main">>;

export default model;
